import React from 'react'
import styled, { css } from 'styled-components'

export const styles = css`
  display: inline-block;
  background: transparent;
  transition: border 0.35s ease, background 0.35s ease, color 0.35s ease;
  padding: 8px 16px;
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  height: 46px;

  @media (max-width: 365px) {
    height: 38px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
  }

  ${props =>
    props.design === 'focus' &&
    css`
      height: auto;
      background: #fff;
      color: ${props => props.theme.colors.primary};
      border: 1px solid #fff;
      padding: 12px 32px;

      &:hover {
        background-color: transparent;
        color: #fff;
      }

      @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media (max-width: 365px) {
        height: auto;
      }
    `}
`

const Wrapper = styled.a`
  ${styles}
`

const Button = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default Button
