import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Content from './Content'
import Title from './Title'
import Button from './Button'
import Text from './Text'

const Wrapper = styled.div`
  padding-bottom: 190px;

  @media (max-width: 768px) {
    padding-bottom: 120px;
  }

  @media (max-width: 375px) {
    padding-bottom: 60px;
  }
`

// TODO: Reuse as Hero Component.
const StyledImg = styled(Img)`
  min-height: 190px;
  max-height: 690px;
`

const Header = styled.div`
  padding-top: 100px;
  padding-bottom: 85px;

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 65px;
  }

  @media (max-width: 375px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
`

const StyledTitle = styled(Title)`
  margin: 0 0 0.5rem 0;
`

const Description = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
`

const Action = styled.div`
  padding-left: calc(50% + 60px);
  vertical-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;

  @media (max-width: 1024px) {
    padding-left: 0;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  @media (max-width: 375px) {
    margin-top: 15px;
  }
`

const ActionItem = styled.div`
  margin-right: 26px;
  margin-bottom: 26px;

  @media (max-width: 375px) {
    margin-right: 12px;
    margin-bottom: 12px;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: center;
`

const StyledEmailButton = styled(StyledButton)`
  padding-left: 10px;
  padding-right: 10px;

  g {
    transition: stroke 0.35s ease;
  }

  &:hover {
    g {
      stroke: #fff;
    }
  }
`

const Svg = styled.svg`
  display: block;
  height: 26px;
  width: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 40px;
  }
`

const SvgDownload = styled.svg`
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  position: relative;
  top: 1px;
  margin-left: -1px;
`

const Lawyer = ({ name, photo, description, content, email, cv }) => (
  <Wrapper>
    <StyledImg fluid={photo.fluid} />
    <Content>
      <Header>
        <StyledTitle align="center">{name}</StyledTitle>
        <Description>{description}</Description>
      </Header>
      <Fade bottom>
        <Text columns={2}>
          {documentToReactComponents(content, {
            renderText: text =>
              text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
          })}
        </Text>
      </Fade>
      <Fade bottom>
        <Action>
          <ActionItem>
            <StyledEmailButton href={`mailto:${email}`}>
              <Svg
                data-name="Ebene 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 39.06 25.93"
              >
                <g
                  fill="none"
                  stroke="#1e805f"
                  strokeMiterlimit={10}
                  strokeWidth=".5"
                >
                  <rect x=".25" y=".25" width="38.56" height="25.43" />
                  <polyline points="0.25 0.25 19.53 19.72 38.81 0.25" />
                  <line x1=".25" y1="25.68" x2="10.49" y2="11.57" />
                  <line x1="28.57" y1="11.57" x2="38.81" y2="25.68" />
                </g>
              </Svg>
            </StyledEmailButton>
          </ActionItem>
          <ActionItem>
            <StyledButton href={cv} type="download" target="_blank">
              <SvgDownload
                data-name="Ebene 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10.84 7.05"
              >
                <polyline
                  points="10.46 0.32 5.42 6.28 0.38 0.32"
                  fill="none"
                  stroke="currentColor"
                  strokeMiterlimit={10}
                />
              </SvgDownload>
              Curriculum Vitae (PDF)
            </StyledButton>
          </ActionItem>
          {/*
          <ActionItem>
            <StyledButton href="/blog/publikationen/">
              Publikationen
            </StyledButton>
          </ActionItem>
          */}
        </Action>
      </Fade>
    </Content>
  </Wrapper>
)

export default Lawyer
