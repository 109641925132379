import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Text from '../components/Text'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Lawyer from '../components/Lawyer'
import Spacer from '../components/Spacer'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const LawyersPage = ({ data }) => {
  const intl = useIntl()

  const lawyers = data.allContentfulTeam.edges.map(({ node }) => {
    return {
      id: node.id,
      name: node.name,
      photo: node.photo,
      description: node.description,
      content: node.content.json,
      email: node.email,
      cv: node.cv.file.url,
      locale: node.node_locale,
    }
  })

  const lawyersLocale = lawyers.filter(lawyer => lawyer.locale === intl.locale)

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'nav.lawyers' })} />
      <Intro image={data.introImage} objectPosition="75% center" />
      <Content>
        <Title align="center">
          {intl.formatMessage({ id: 'lawyers.heading' })}
        </Title>
        <Fade bottom>
          <Text align="center" size="intro">
            {intl.formatMessage({ id: 'lawyers.intro' })}
          </Text>
        </Fade>
      </Content>
      <Spacer />
      {lawyersLocale.map(lawyer => (
        <Lawyer
          key={lawyer.id}
          photo={lawyer.photo}
          name={lawyer.name}
          description={lawyer.description}
          content={lawyer.content}
          email={lawyer.email}
          cv={lawyer.cv}
        />
      ))}
    </Layout>
  )
}

export default LawyersPage

export const query = graphql`
  query {
    introImage: file(relativePath: { eq: "anwaelte_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allContentfulTeam(sort: { fields: name }) {
      edges {
        node {
          id
          node_locale
          photo {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          description
          content {
            json
          }
          email
          cv {
            file {
              url
            }
          }
        }
      }
    }
  }
`
